import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import HeadMeta from "../components/HeadMeta"
import PageHeader from "../components/PageHeader"
import Breadcrumb from "../components/Breadcrumb"
import BrowseList from "../components/BrowseList"
import RequestAPhrase from "../components/RequestAPhrase"

export default ({ data }) => {
  return (
    <Layout>
      <HeadMeta pageTitle="Phrases" />
      <PageHeader>Phrases</PageHeader>
      <Breadcrumb
        route={[
          { name: "Multilingual library signage", href: "/" },
          { name: "Phrases", href: "/phrase" },
        ]}
      />

      <h2>Browse by phrase</h2>
      <BrowseList
        items={data.allPhrasesJson.nodes}
        placeholderPrefix="Enter phrase"
        sortAlpha={false}
      />

      <RequestAPhrase />
    </Layout>
  )
}

export const query = graphql`
  query PhraseIndexQuery {
    allPhrasesJson(filter: { fields: { name: { ne: "" } } }) {
      nodes {
        fields {
          name
          slug
        }
      }
    }
  }
`
